import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

import COLORS from 'css/colors';
import FONTS from 'css/fonts';
import BREAKPOINTS from 'css/breakpoints';

import { HeroProps } from './types';

export const Image = styled(BackgroundImage)<HeroProps>`
  padding-top: 52%;

  @media (min-width: ${BREAKPOINTS.sm.min}px) {
    padding-top: ${(props) => (props.isSmall ? '30%' : '55%')};
  }
`;

export const Title = styled.h1`
  position: relative;
  font-family: ${FONTS.primary};
  color: ${COLORS.pureWhite};
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;
  width: 78%;
  text-align: center;

  &:after {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 20px / 2);
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${COLORS.pureWhite};

    @media (min-width: ${BREAKPOINTS.sm.min}px) {
      bottom: -30px;
      height: 3px;
    }

    @media (min-width: ${BREAKPOINTS.md.min}px) {
      left: calc(50% - 30px / 2);
      width: 30px;
      bottom: -40px;
    }

    @media (min-width: ${BREAKPOINTS.lg.min}px) {
      left: calc(50% - 40px / 2);
      width: 40px;
      bottom: -50px;
    }
  }

  @media (min-width: ${BREAKPOINTS.sm.min}px) {
    font-size: 42px;
    width: 62%;
  }

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    font-size: 52px;
  }

  @media (min-width: ${BREAKPOINTS.lg.min}px) {
    font-size: 72px;
    width: 65%;
  }

  @media (min-width: ${BREAKPOINTS.xl.min}px) {
    font-size: 82px;
    width: 60%;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    top: 50%;
  }
`;
