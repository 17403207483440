import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import FONT from 'css/fonts';
import COLORS from 'css/colors';
import BREAKPOINTS from 'css/breakpoints';

type MobileIconProps = {
  isMobileNavOpen?: boolean;
};

export const StyledImg = styled(Img)`
  width: 125px;

  @media (min-width: ${BREAKPOINTS.sm.min}px) {
    width: 90px;
    height: 80px;
  }

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    width: 125px;
    height: 110px;
  }

  @media (min-width: ${BREAKPOINTS.lg.min}px) {
    width: 150px;
    height: 135px;
  }
`;

export const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-family: ${FONT.primary};
  color: ${COLORS.pureWhite};
  font-weight: 600;
  text-decoration: none;
  transition: color 0.4s ease-out;
  padding-bottom: 6px;

  &:hover {
    color: ${COLORS.red};
  }

  // sliding border on hover
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 3px;
    background: ${COLORS.pureWhite};
    height: 1px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    left: 0;
    right: 0;
  }
`;

export const Wrapper = styled.div<MobileIconProps>`
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  padding: 20px 15px;
  margin: 0 auto;
  order: 2;

  ${StyledLink}:not(:first-of-type) {
    margin-left: 30px;

    ${(props) =>
      props.isMobileNavOpen &&
      `
      margin-left: 0;
    `}
  }

  ${StyledLink} {
    ${(props) =>
      props.isMobileNavOpen &&
      `
      margin-left: 0;
      margin-top: 30px;
      font-size: 22px;
    `}
  }

  ${(props) =>
    props.isMobileNavOpen &&
    `
    flex-direction: column;
    align-items: flex-end;
  `}
`;

export const Container = styled.header<MobileIconProps>`
  position: absolute;
  width: 100%;
  z-index: 1;

  ${(props) =>
    props.isMobileNavOpen &&
    `
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: ${COLORS.pureWhite};
  `}
`;

export const MobileIcon = styled.div<MobileIconProps>`
  width: 30px;

  @media (min-width: ${BREAKPOINTS.sm.min}px) {
    display: none;
  }

  &:after,
  &:before,
  & div {
    background-color: ${COLORS.pureWhite};
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 6px 0;
    transition: all 0.2s ease-in-out;
  }

  ${(props) =>
    props.isMobileNavOpen &&
    `
    &:after,
    &:before,
    & div {
      background-color: ${COLORS.pureBlack};
    }

    &:before {
      transform: translateY(9px) rotate(135deg);
    }

    &:after {
      transform: translateY(-9px) rotate(-135deg);
    }

    & div {
      transform: scale(0);
    }
  `}
`;

export const LinksWrapper = styled.div<MobileIconProps>`
  display: none;

  @media (min-width: ${BREAKPOINTS.sm.min}px) {
    display: block;
  }

  ${(props) =>
    props.isMobileNavOpen &&
    `
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;

      ${StyledLink} {
        color: ${COLORS.pureBlack};
      }
    `}
`;

export const MobileMenu = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.pureWhite};
`;
