import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Wrapper } from './FooterStyled';
import P from 'components/P';

const Footer: React.FC = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query FooterQuery {
        markdownRemark(fileAbsolutePath: { regex: "/footer/" }) {
          frontmatter {
            footer_copy
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <P textAlign="center">{markdownRemark.frontmatter.footer_copy}</P>
    </Wrapper>
  );
};

export default Footer;
