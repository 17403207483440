import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import BREAKPOINTS from 'css/breakpoints';
import {
  Container,
  Wrapper,
  StyledImg,
  StyledLink,
  MobileIcon,
  LinksWrapper,
} from './NavbarStyled';

type LinkProps = {
  slug: string;
  name: string;
};

const Navbar: React.FC = () => {
  const [isMobileNavOpen, toggleMobileNav] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > BREAKPOINTS.xs.max) {
        toggleMobileNav(false);
      }
    };

    window.addEventListener('resize', handleResize);
  });
  const { logo, logoMobile, logoMobileDark, markdownRemark } = useStaticQuery(
    graphql`
      query NavbarQuery {
        markdownRemark(fileAbsolutePath: { regex: "/navbar/" }) {
          frontmatter {
            navbar_links {
              name
              slug
            }
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMobile: file(relativePath: { eq: "logo_small.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMobileDark: file(relativePath: { eq: "logo_dark.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  const handleLinkClick = (slug: string) => {
    if (isMobileNavOpen && location.pathname === slug) {
      toggleMobileNav(false);
    }
  };

  const pictures = [
    {
      ...logoMobile.childImageSharp.fluid,
      media: `(max-width: 575px)`,
    },
    logo.childImageSharp.fluid,
  ];

  return (
    <Container isMobileNavOpen={isMobileNavOpen}>
      <Wrapper isMobileNavOpen={isMobileNavOpen}>
        {!isMobileNavOpen && (
          <Link to="/" title="Motoroma - Home">
            <StyledImg fluid={pictures} alt="Motoroma Serwis logo" />
          </Link>
        )}
        <MobileIcon
          isMobileNavOpen={isMobileNavOpen}
          onClick={() => toggleMobileNav(!isMobileNavOpen)}
        >
          <div></div>
        </MobileIcon>
        <LinksWrapper isMobileNavOpen={isMobileNavOpen}>
          {isMobileNavOpen && (
            <StyledImg
              fluid={logoMobileDark.childImageSharp.fluid}
              alt="Motoroma Serwis logo"
            />
          )}
          {markdownRemark.frontmatter.navbar_links.map((link: LinkProps) => (
            <StyledLink
              key={link.name}
              to={link.slug}
              title={`Motoroma - ${link.name}`}
              onClick={() => handleLinkClick(link.slug)}
            >
              {link.name}
            </StyledLink>
          ))}
        </LinksWrapper>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
