import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

type SEOProps = {
  metaTitle?: string;
  metaDescription?: string;
  pathname: string;
};

const SEO: React.FC<SEOProps> = ({ metaTitle, metaDescription, pathname }) => {
  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
          }
        }
      }
    `
  );
  const { siteUrl, image } = site.siteMetadata;
  const seo = {
    title: metaTitle,
    description: metaDescription,
    url: `${siteUrl}${pathname || '/'}`,
    image,
  };
  const schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'MotorcycleRepair',
      url: seo.url,
      name: seo.title,
      description: seo.description,
      image,
      telephone: '(48) 669 428 678',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Witaszyczki 36',
        addressLocality: 'Witaszyce',
        postalCode: '63-230',
        addressCountry: 'Polska',
      },
      openingHours: 'Mo, Tu, We, Th, Fr 08:00-17:00 Sa 08:00-14:00',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '(48) 669 428 678',
        contactType: 'Obsługa klienta',
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <html lang="pl" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:site_name" content={seo.title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta
          name="google-site-verification"
          content="yKQbxZKgd5mswVCRdEZXddUki6ldVK_aYJHcKTNWbOo"
        />
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

export default SEO;
