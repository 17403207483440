import React from 'react';
import { createGlobalStyle } from 'styled-components';

import Hero from 'components/Hero';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import SEO from 'components/SEO';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

type LayoutProps = {
  smallHero?: boolean;
  title: string;
  metaTitle: string;
  metaDescription: string;
  slug: string;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  smallHero,
  title,
  metaTitle,
  metaDescription,
  slug,
}) => (
  <>
    <GlobalStyle />
    <SEO
      pathname={slug}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    />
    <Navbar />
    <Hero isSmall={smallHero} title={title} />
    {children}
    <Footer />
  </>
);

export default Layout;
