import styled from 'styled-components';

import COLORS from 'css/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: ${COLORS.lightGray};
`;
