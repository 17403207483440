type BreakpointsProps = {
  [key: string]: {
    [key: string]: number;
  };
};

const BREAKPOINTS: BreakpointsProps = {
  xs: {
    min: 0,
    max: 575,
  },
  sm: {
    min: 576,
    max: 767,
  },
  md: {
    min: 768,
    max: 991,
  },
  lg: {
    min: 992,
    max: 1440,
  },
  xl: {
    min: 1441,
    max: Infinity,
  },
};

export default BREAKPOINTS;
