import styled from 'styled-components';
import { Link } from 'gatsby';

import COLORS from 'css/colors';
import FONTS from 'css/fonts';
import BREAKPOINTS from 'css/breakpoints';
import { DarkBgProps, NoButtonProps } from './types';

export const Container = styled.section<DarkBgProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 25px;
  background-color: ${(props) =>
    props.darkBackground ? COLORS.lightGray : COLORS.pureWhite};
  max-width: 960px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    padding: 80px 55px;
  }

  @media (min-width: ${BREAKPOINTS.lg.min}px) {
    padding: 100px 85px;
  }
`;

export const StyledLink = styled(Link)<NoButtonProps>`
  background-color: ${COLORS.pureWhite};
  font-family: ${FONTS.primary};
  font-weight: 600;
  padding: 12px 16px;
  color: ${COLORS.gray};
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px;
  border: 2px solid ${COLORS.gray};
  transition: all 0.4s ease-out;

  &:hover {
    transform: translateY(-2px);

    -webkit-box-shadow: 0 10px 6px -6px ${COLORS.dove};
    -moz-box-shadow: 0 10px 6px -6px ${COLORS.dove};
    box-shadow: 0 10px 6px -6px ${COLORS.dove};
  }

  ${(props) =>
    props.$noButton &&
    `
    display: none;
  `}
`;

export const Header = styled.h2`
  position: relative;
  font-family: ${FONTS.primary};
  color: ${COLORS.pureBlack};
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    font-size: 24px;
    margin-bottom: 90px;
  }

  @media (min-width: ${BREAKPOINTS.lg.min}px) {
    font-size: 26px;
  }

  &:after {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 20px / 2);
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${COLORS.red};

    @media (min-width: ${BREAKPOINTS.md.min}px) {
      bottom: -30px;
      height: 3px;
    }

    @media (min-width: ${BREAKPOINTS.lg.min}px) {
      left: calc(50% - 40px / 2);
      width: 40px;
    }
  }
`;
