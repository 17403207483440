type ColorsProps = {
  [key: string]: string;
};

const COLORS: ColorsProps = {
  pureWhite: '#FFFFFF',
  red: '#FF0000',
  pureBlack: '#000000',
  gray: '#575757',
  lightGray: '#F1F1F1',
  dove: '#D6D6D6',
  facebook: '#4267b2',
  green: '#4CAF50',
  babyBlue: '#A1CAF1',
};

export default COLORS;
