import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Image, Container, Title } from './HeroStyled';

import { HeroProps } from './types';

const Hero: React.FC<HeroProps> = ({ isSmall, title }) => {
  const { hero } = useStaticQuery(
    graphql`
      query HeroQuery {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Image
      fluid={hero.childImageSharp.fluid}
      isSmall={isSmall}
      alt="Czarno białe zdjęcie silnika motoru."
    >
      <Container>
        <Title>{title}</Title>
      </Container>
    </Image>
  );
};

export default Hero;
