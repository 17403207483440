import React from 'react';
import { Container, StyledLink, Header } from './SectionStyled';
import { SectionProps, DarkBgProps, NoButtonProps } from './types';
import { WithoutHeaderProps } from 'components/types';

const Section: React.FC<
  SectionProps & DarkBgProps & NoButtonProps & WithoutHeaderProps
> = ({
  header,
  children,
  darkBackground,
  $noButton,
  withoutHeader,
  path = '/',
}) => (
  <Container darkBackground={darkBackground}>
    {!withoutHeader && <Header>{header}</Header>}
    {children}
    <StyledLink
      to={path as string}
      title={`Motoroma - ${header}`}
      $noButton={$noButton}
    >
      Czytaj więcej
    </StyledLink>
  </Container>
);

export default Section;
