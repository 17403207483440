import styled from 'styled-components';

import BREAKPOINTS from 'css/breakpoints';
import COLORS from 'css/colors';
import FONTS from 'css/fonts';

type PProps = {
  textAlign?: string;
};

const P = styled.p<PProps>`
  font-family: ${FONTS.primary};
  color: ${COLORS.gray};
  line-height: 24px;
  font-size: 14px;
  width: 100%;
  margin: 0;
  ${(props) =>
    props.textAlign &&
    `
    text-align: ${props.textAlign};
  `}

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    line-height: 28px;
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS.lg.min}px) {
    line-height: 32px;
  }
`;

export default P;
